<template>
  <div class="property-wrapper">
    <template v-if="controls.length == 1 && control.synopticComponent">
      <ControlBaseProperties
        :value="control"
        @input="updateBase"
        :hasExpression="true"
      />

      <ControlSpecificPanel :control="control">
        <div class="inner-panel">
          <TogglePanel
            title="arc_and_scale"
            class="toggle-panel"
            :collapsed="true"
          >
            <!-- begin label  -->
            <DataFormatInput
              :value="control.format"
              :control="control"
              :toggleIcon="labelShow ? 'fa fa-eye' : 'fa fa-eye-slash'"
              @input="updateFormat($event)"
              @toggle="labelShow = !labelShow"
              class="form-group-sm limit-box"
              style="margin-bottom: 0px"
            >
              <template #label>
                <label for="">Label</label>
              </template>
            </DataFormatInput>
            <div class="form-group form-group-sm limit-box">
              <div class="input-group" style="width: 100%">
                <input
                  type="number"
                  class="form-control inline-input"
                  style="width: 50%"
                  v-model="labelOffsetX"
                />
                <input
                  type="number"
                  class="form-control inline-input"
                  style="width: 50%"
                  v-model="labelOffsetY"
                />
                <div class="inline-legend">
                  <div>
                    <div>
                      <div style="left: 0; top: 2px">
                        Offset {{ $t("horizontal") }}
                      </div>
                      <div style="left: 50%; top: 2px">
                        Offset {{ $t("vertical") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end label -->

            <!-- begin pointer -->
            <div class="form-group form-group-sm limit-box">
              <label for="">{{ $t("pointer") }}</label>
              <div class="input-group">
                <div
                  class="input-group-addon btn eye-icon"
                  @click="toggle(control.synopticComponent.pointer, 'show')"
                >
                  <i
                    class="fa fa-eye"
                    v-if="control.synopticComponent.pointer.show"
                  ></i>
                  <i class="fa fa-eye-slash" v-else></i>
                </div>
                <!-- attention! echarts version 5 -->
                <!-- <div class="input-group-addon btn no-padding">
                  <ColorPicker
                    class="color-picker"
                    :pickerStyle="{ left: '0px' }"
                    v-model="control.synopticComponent.pointer.color"
                  />
                </div> -->
                <input
                  type="number"
                  class="form-control inline-input"
                  style="width: 50%"
                  v-model="control.synopticComponent.pointer.width"
                />
                <input
                  type="number"
                  class="form-control inline-input"
                  style="width: 50%"
                  v-model="control.synopticComponent.pointer.height"
                />

                <div class="inline-legend">
                  <div>
                    <div>
                      <div style="left: 0">{{ $t("width") }}</div>
                      <div style="left: 50%">{{ $t("height") }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end pointer -->
            <!-- begin scale-->
            <div class="form-group form-group-sm limit-box">
              <label for="">{{ $t("scale") }}</label>
              <div class="input-group">
                <div
                  class="input-group-addon btn eye-icon"
                  @click="toggle(control.synopticComponent.scale, 'show')"
                >
                  <i
                    class="fa fa-eye"
                    v-if="control.synopticComponent.scale.show"
                  ></i>
                  <i class="fa fa-eye-slash" v-else></i>
                </div>
                <div class="input-group-addon btn no-padding">
                  <ColorPicker
                    class="color-picker"
                    v-model="control.synopticComponent.scale.color"
                  />
                </div>
                <div
                  class="input-group-addon btn eye-icon"
                  :class="{
                    'text-blue text-strong':
                      control.synopticComponent.scale.bold
                  }"
                  @click="toggle(control.synopticComponent.scale, 'bold')"
                >
                  <i class="fa fa-bold"></i>
                </div>

                <!-- attention: echarts version 5 -->
                <!--
                <input
                  type="number"
                  class="form-control inline-input"
                  style="width:35%"
                  v-model="control.synopticComponent.scale.height"
                />
                <input
                  type="number"
                  class="form-control inline-input"
                  style="width:30%"
                  v-model="control.synopticComponent.scale.offset"
                />               
                <div class="inline-legend">
                  <div>
                    <div>
                      <div style="width: 35%;">{{ $t("width") }}</div>
                      <div style="width: 35%;">{{ $t("height") }}</div>
                      <div style="width: 30%;">{{ $t("offset") }}</div>
                    </div>
                  </div>
                </div>
                -->
                <!-- attention: echarts version 4 -->
                <input
                  type="number"
                  class="form-control inline-input"
                  style="width: 34%"
                  v-model="control.synopticComponent.scale.width"
                />
                <input
                  type="number"
                  class="form-control inline-input"
                  style="width: 32%"
                  v-model="control.synopticComponent.scale.height"
                />
                <input
                  type="number"
                  class="form-control inline-input"
                  style="width: 34%; z-index: 1"
                  v-model="control.synopticComponent.scale.nElements"
                />
                <div class="inline-legend no-select">
                  <div>
                    <div>
                      <div style="left: 0">{{ $t("size") }}</div>
                      <div style="left: 34%">Offset</div>
                      <div style="left: 66%">
                        {{ $t("split") }}
                      </div>
                      <div
                        class="clicable"
                        style="right: 5px"
                        @click.stop.prevent="incScalePrecision"
                      >
                        <span
                          style="vertical-align: middle; font-size: 80%"
                          class="fa fa-arrow-left"
                        >
                        </span>
                        <span
                          style="vertical-align: text-bottom; line-height: 1em"
                          >{{
                            this.control.synopticComponent.scale.precision || 0
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end scale -->
            <!-- begin rays -->
            <div class="form-group form-group-sm limit-box">
              <label for="">{{ $t("rays") }}</label>
              <div class="input-group">
                <div
                  class="input-group-addon btn eye-icon"
                  @click="toggle(control.synopticComponent.tick, 'show')"
                >
                  <i
                    class="fa fa-eye"
                    v-if="control.synopticComponent.tick.show"
                  ></i>
                  <i class="fa fa-eye-slash" v-else></i>
                </div>
                <div class="input-group-addon btn no-padding">
                  <ColorPicker
                    class="color-picker"
                    :pickerStyle="{left: '0px'}"
                    v-model="control.synopticComponent.tick.color"
                  />
                </div>
                <!-- attention: echarts version 5 -->
                <!-- 
                <input
                  type="number"
                  class="form-control inline-input"
                  style="width:35%"
                  v-model="control.synopticComponent.tick.width"
                />
                <input
                  type="number"
                  class="form-control inline-input"
                  style="width:35%"
                  v-model="control.synopticComponent.tick.height"
                />
                <input
                  type="number"
                  class="form-control inline-input"
                  style="width:30%"
                  v-model="control.synopticComponent.tick.offset"
                />
                <div class="inline-legend">
                  <div>
                    <div>
                      <div style="width: 35%;">{{ $t("width") }}</div>
                      <div style="width: 35%;">{{ $t("height") }}</div>
                      <div style="width: 30%;">{{ $t("offset") }}</div>
                    </div>
                  </div>
                </div>
                -->
                <input
                  type="number"
                  class="form-control inline-input"
                  style="width: 50%"
                  v-model="control.synopticComponent.tick.width"
                />
                <input
                  type="number"
                  class="form-control inline-input"
                  style="width: 50%"
                  v-model="control.synopticComponent.tick.height"
                />
                <div class="inline-legend">
                  <div>
                    <div>
                      <div style="left: 0">{{ $t("width") }}</div>
                      <div style="left: 50%">{{ $t("height") }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end rays -->

            <!-- begin arc -->
            <div class="form-group form-group-sm limit-box">
              <label for="">{{ $t("arc") }}</label>
              <div class="input-group">
                <div
                  class="input-group-addon btn eye-icon"
                  @click="toggle(control.synopticComponent.bar, 'show')"
                >
                  <i
                    class="fa fa-eye"
                    v-if="control.synopticComponent.bar.show"
                  ></i>
                  <i class="fa fa-eye-slash" v-else></i>
                </div>
                <div
                  class="input-group-addon btn no-padding"
                  v-if="!isMultiLimit"
                >
                  <ColorPicker
                    class="color-picker"
                    :pickerStyle="{left: '0px'}"
                    :value="control.synopticComponent.bar.color"
                    @input="setDefaultBarColor($event)"
                  />
                </div>
                <input
                  type="number"
                  class="form-control inline-input"
                  style="width: 32%"
                  v-model="control.synopticComponent.bar.width"
                />
                <input
                  type="number"
                  class="form-control inline-input"
                  style="width: 34%"
                  v-model="control.synopticComponent.bar.angleIni"
                />
                <input
                  type="number"
                  class="form-control inline-input"
                  style="width: 34%"
                  v-model="control.synopticComponent.bar.angleEnd"
                />
                <div class="inline-legend">
                  <div>
                    <div>
                      <div style="left: 0">
                        {{ $t("width") }}
                      </div>
                      <div style="left: 32%">
                        {{ $t("start") }}
                      </div>
                      <div style="left: 66%">
                        {{ $t("stop") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end arc -->
          </TogglePanel>
          <TogglePanel title="limits" class="toggle-panel" :collapsed="true">
            <div style="margin-bottom: 10px">
              <div class="form-group form-group-sm">
                {{ $t("multiple_levels") }}
                <label for="opt_no" class="radio-label clicable no-select">
                  <input
                    type="radio"
                    id="opt_no"
                    :value="false"
                    v-model="isMultiLimit"
                  />{{ $t("no") }}
                </label>
                <label for="opt_yes" class="radio-label clicable no-select">
                  <input
                    type="radio"
                    id="opt_yes"
                    :value="true"
                    v-model="isMultiLimit"
                  />{{ $t("yes") }}
                </label>
              </div>
            </div>
            <MinMaxForm v-model="minmax" />
            <!-- multi-level -->
            <div style="margin: 10px 5px 0 10px">
              <TogglePanel
                :title="$tc('level', 2)"
                v-if="isMultiLimit"
                :collapsed="true"
              >
                <div
                  class="limit-box form-group form-group-sm"
                  v-if="isMultiLimit"
                >
                  <div class="input-group">
                    <div class="input-group-addon">
                      <span class="text-primary text-bold">{{
                        $tc("value", 1)
                      }}</span>
                    </div>
                    <select
                      class="form-control form-group-sm no-padding"
                      v-model="intervalSource"
                    >
                      <option value="constant">{{ $t("constant") }}</option>
                      <option value="percentage">{{ $t("percentage") }}</option>
                      <option value="data">{{ $t("from_data") }}</option>
                    </select>
                  </div>
                </div>

                <div class="form-group form-group-sm limit-box">
                  <div
                    class="btn text-center text-primary text-bold clicable no-select"
                    style="width: 100%"
                    @click.stop.prevent="toggleColorDir"
                  >
                    <i
                      :class="
                        colorDir
                          ? 'fa fa-long-arrow-down'
                          : 'fa fa-long-arrow-up'
                      "
                    >
                    </i>
                    {{ $tc("color_pattern", 1) }}
                  </div>
                </div>

                <div
                  v-for="(item, ix) in control.synopticComponent.interval.items"
                  :key="ix"
                >
                  <div
                    class="form-group form-group-sm"
                    style="margin-bottom: 1px"
                  >
                    <div class="input-group">
                      <div class="input-group-addon btn no-padding">
                        <ColorPicker
                          class="color-picker"
                          :value="item.color"
                          :pickerStyle="{left: '0px'}"
                          @input="setColor(ix, $event)"
                        />
                      </div>
                      <div
                        class="form-control text-center clicable no-select"
                        v-if="intervalSource == 'data'"
                        @click.stop.prevent="toggleDataItem(ix)"
                      >
                        {{ $tc("data", 1) }}
                        <div class="pull-right">
                          <i
                            class="fa fa-angle-up"
                            v-if="visibleDataItems[ix]"
                          ></i>
                          <i class="fa fa-angle-down" v-else></i>
                        </div>
                      </div>
                      <template v-else>
                        <input
                          class="form-control level-input"
                          :class="{
                            'text-right': intervalSource == 'percentage',
                            'text-center': intervalSource == 'constant',
                            'has-error': hasColorError(ix)
                          }"
                          type="number"
                          ref="levelInput"
                          @input="setColorSize(ix, $event.target.value)"
                          :value="`${getColorSize(ix).value}`"
                          :title="
                            $t(hasColorError(ix) ? 'out_of_range' : 'value')
                          "
                        />
                        <div
                          class="input-group-addon level-input-unit"
                          @click.stop.prevent="levelInputSetFocus(ix)"
                          v-if="intervalSource == 'percentage'"
                        >
                          <!-- <i
                            style="margin-top:2px;font-size:8pt"
                            class="fa fa-percent"
                          ></i> -->
                          <span>%</span>
                        </div>
                      </template>
                      <div
                        class="input-group-addon btn"
                        @click.stop.prevent="delColor(ix)"
                        :title="intervalSource"
                      >
                        <i class="fa fa-trash"></i>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="intervalSource == 'data' && visibleDataItems[ix]"
                    class="interval-form"
                  >
                    <ValueSourceForm
                      :value="interval(ix)"
                      @input="interval(ix, $event)"
                      :typeList="['data']"
                    />
                  </div>
                </div>
                <div class="bottom-toolbar">
                  <div v-if="showAddColor">
                    <ColorPicker
                      class="color-picker"
                      icon="fa fa-plus"
                      @input="setColor(-1, $event)"
                      :pickerStyle="{left: '0px'}"
                    />
                  </div>
                  <div v-if="intervalSource == 'constant'">
                    <span
                      class="btn btn-default btn-xs button-import-alarm"
                      @click.stop.prevent="importFromAlarm"
                      :disabled="importing"
                    >
                      <i class="fa fa-refresh fa-spin" v-if="importing"></i>
                      <i class="fa fa-bell" v-else></i>
                      <span style="padding: 0 5px"
                        >{{ $t("import_from_alarm") }}
                      </span>
                    </span>
                  </div>
                </div>
              </TogglePanel>
            </div>
          </TogglePanel>
        </div>
      </ControlSpecificPanel>
    </template>
  </div>
</template>

<script>
import {isEqual} from "lodash";
import ControlBaseProperties from "./control-base-properties";
import BaseControl from "./base-control.vue";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import ControlSpecificPanel from "@/components/editor/control-specific-panel.vue";
import ColorPicker from "@/components/editor/color-picker";
import AlarmService from "@/services/alarm.js";
import DataFormatInput from "@/components/synoptic/property-editor/controls/data-format-input.vue";
import MinMaxForm from "@/components/synoptic/property-editor/controls/minmax-form.vue";
import ValueSourceForm from "@/components/synoptic/property-editor/controls/value-source-form.vue";
import Controls from "@/assets/dashboard/controls.json";

const defaultControl = function() {
  return Controls.find(
    (item) => item.template.synopticComponent.componentName == "SynopticGauge"
  );
};

const defaultItervalItem = function() {
  let control = defaultControl();
  let item = control.template.synopticComponent.interval.items[0];
  return JSON.parse(JSON.stringify(item));
};

const defaultLabel = function() {
  let control = defaultControl();
  let label = control.template.synopticComponent.label;
  return JSON.parse(JSON.stringify(label));
};

const colorList = [
  "#4b94bf",
  "#f39c12",
  "#00c0ef",
  "#00a65a",
  "#dd4b39",
  "#828282",
  "#cda819",
  "#9966ff",
  "#4b94bf",
  "#f39c12",
  "#00c0ef",
  "#00a65a"
];

export {defaultLabel};

export default {
  name: "Gauge",
  extends: BaseControl,
  components: {
    ControlBaseProperties,
    TogglePanel,
    ControlSpecificPanel,
    ColorPicker,
    DataFormatInput,
    MinMaxForm,
    ValueSourceForm
  },
  data() {
    return {
      control: {},
      skipUpdate: false,
      merge: true,
      showAddColor: true,
      colorSizeError: false,
      visibleDataItems: [],
      importing: false,
      colorDir: false
    };
  },
  computed: {
    minmax: {
      set(value) {
        this.$set(this.control.synopticComponent, "min", value.min);
        this.$set(this.control.synopticComponent, "max", value.max);
      },
      get() {
        return {
          min: this.control.synopticComponent.min,
          max: this.control.synopticComponent.max
        };
      }
    },
    isMultiLimit: {
      set(value) {
        this.$set(
          this.control.synopticComponent,
          "fill",
          value ? "single" : "none"
        );
      },
      get() {
        return this?.control?.synopticComponent?.fill != "none";
      }
    },
    vertical: {
      set(value) {
        if (value != this?.control.synopticComponent.vertical) {
          this.control.synopticComponent.vertical = value;
          let r = JSON.parse(
            JSON.stringify(this.control.synopticComponent.clientRect)
          );

          let w = r.width;
          let h = r.height;

          if (value) {
            r.left = r.left + (Math.round(w / 2) - Math.round(h / 2));
            r.top = r.top - Math.round(w / 2) + Math.round(h / 2);
          } else {
            r.left = r.left - Math.round(h / 2) + Math.round(w / 2);
            r.top = r.top + Math.round(h / 2) - Math.round(w / 2);
          }
          r.width = h;
          r.height = w;
          this.$set(this.control.synopticComponent, "clientRect", r);
        }
      },
      get() {
        return this?.control?.synopticComponent?.vertical || false;
      }
    },
    intervalSource: {
      set(value) {
        let interval = this?.control?.synopticComponent?.interval || {};
        interval.source = value;
        // clean up
        (interval.items || []).forEach((item) => {
          if (value == "data") {
            item.type = "data";
          } else if (value == "constant" || value == "percentage") {
            item.type = "constant";
          }
        });
        this.$set(this.control.synopticComponent, "interval", interval);
      },
      get() {
        return (
          this?.control?.synopticComponent?.interval?.source || "percentage"
        );
      }
    },
    defaultLabel() {
      // backward compatibility (since label attribute does not exists )
      return {
        ...defaultLabel(),
        ...(this?.control?.synopticComponent?.label || {})
      };
    },
    labelShow: {
      set(value) {
        let label = JSON.parse(JSON.stringify(this.defaultLabel));
        label.show = value;
        this.$set(this.control.synopticComponent, "label", label);
      },
      get() {
        let label = this.defaultLabel;
        return label?.show;
      }
    },
    labelOffsetX: {
      set(value) {
        this.__labelOffSet__(0, value);
      },
      get() {
        return this.__labelOffSet__(0);
      }
    },
    labelOffsetY: {
      set(value) {
        this.__labelOffSet__(1, value);
      },
      get() {
        return this.__labelOffSet__(1);
      }
    }
  },
  watch: {
    control: {
      deep: true,
      handler(val) {
        this.updateControl({
          id: val.id,
          control: this.control,
          noMerge: !this.merge
        });
        this.merge = true;
      }
    },
    controls: {
      deep: true,
      handler(val) {
        if (!this.skipUpdate) {
          let n = JSON.parse(JSON.stringify(val[0]));
          let o = JSON.parse(JSON.stringify(this.control));
          if (!isEqual(n, o)) {
            this.control = JSON.parse(JSON.stringify(n));
          }
        }
        this.skipUpdate = false;
      }
    }
  },
  methods: {
    __labelOffSet__(attr, value) {
      let label = this.defaultLabel;
      if (value !== undefined) {
        label = JSON.parse(JSON.stringify(label));
        label.offset[attr] =
          value !== ""
            ? `${parseInt(value.replace(/[^\-0-9]/g, "")) || 0}%`
            : "";
        this.$set(this.control.synopticComponent, "label", label);
      }
      return `${label.offset[attr]}`.replace(/[^\-0-9]/g, "");
    },
    updateFormat(value) {
      this.skipUpdate = true;
      this.control.format = value;
    },
    setColor(ix, value) {
      let interval = JSON.parse(
        JSON.stringify(
          this?.control?.synopticComponent?.interval || {items: []}
        )
      );
      if (ix >= 0 && ix < interval.items.length) {
        interval.items[ix].color = value;
        this.$set(this.control.synopticComponent, "interval", interval);
      } else if (ix < 0) {
        this.showAddColor = false;
        let item = defaultItervalItem();
        item.color = value;
        if (this?.control?.synopticComponent?.interval?.source == "constant") {
          item.value = 0;
        }
        interval.items.push(item);
        this.$set(this.control.synopticComponent, "interval", interval);
        this.$nextTick(() => {
          this.showAddColor = true;
        });
        this.resizeColors();
      }
    },
    delColor(ix) {
      let interval = JSON.parse(
        JSON.stringify(
          this?.control?.synopticComponent?.interval || {items: []}
        )
      );
      if (ix >= 0 && ix < interval.items.length) {
        this.merge = false;
        interval.items.splice(ix, 1);
        this.$set(this.control.synopticComponent, "interval", interval);
      }
      this.resizeColors();
    },
    resizeColors() {
      if (this?.control?.synopticComponent?.interval?.source != "percentage")
        return;
      let interval = JSON.parse(
        JSON.stringify(
          this?.control?.synopticComponent?.interval || {items: []}
        )
      );
      let len = (interval.items || []).length;
      if (len) {
        let p = len ? Math.round(100 / len) : 0;
        let s = 0;
        interval.items.forEach((item) => {
          s += p;
          item.value = s;
        });
        interval.items[len - 1].value = 100;
      }
      this.$set(this.control.synopticComponent, "interval", interval);
    },
    hasColorError(ix) {
      let vlr = parseFloat(this.getColorSize(ix).value);
      if (this?.control?.synopticComponent?.interval?.source == "percentage") {
        return vlr < 0 || vlr > 100;
      } else if (
        this?.control?.synopticComponent?.interval?.source == "constant"
      ) {
        return (
          vlr < parseFloat(this.control.synopticComponent.min.value) ||
          vlr > parseFloat(this.control.synopticComponent.max.value)
        );
      }
    },
    getColorSize(ix) {
      // let colorSize = this?.control?.synopticComponent?.colorSize || [];
      // if (ix >= 0 && ix < colorSize.length) {
      //   return colorSize[ix];
      // }
      // return "";
      let interval = JSON.parse(
        JSON.stringify(
          this?.control?.synopticComponent?.interval || {items: []}
        )
      );
      if (ix >= 0 && ix < interval.items.length) {
        return interval.items[ix];
      }
      return "";
    },
    setColorSize(ix, value) {
      if (value === "") return;
      let interval = JSON.parse(
        JSON.stringify(
          this?.control?.synopticComponent?.interval || {items: []}
        )
      );
      this.colorSizeError = false;
      if (this?.control?.synopticComponent?.interval?.source == "percentage") {
        interval.items[ix].value = parseInt(value);
        interval.items[interval.items.length - 1].value = 100;
      } else if (
        this?.control?.synopticComponent?.interval?.source == "constant"
      ) {
        let min = this.control.synopticComponent.min;
        let max = this.control.synopticComponent.max;
        if (!min.data_id && value < parseFloat(min.value)) {
          this.colorSizeError = true;
        } else if (!max.data_id && value > parseFloat(max.value)) {
          this.colorSizeError = true;
        }
        interval.items[ix].value = value;
      }
      this.$set(this.control.synopticComponent, "interval", interval);
    },
    toggleDataItem(ix) {
      let items = JSON.parse(JSON.stringify(this.visibleDataItems));
      items[ix] = items[ix] ? false : true;
      this.$set(this, "visibleDataItems", items);
    },
    interval(ix, value) {
      if (ix === undefined) return null;
      let entry = null;
      if (value !== undefined) {
        entry = this?.control?.synopticComponent?.interval || {items: []};
        if (ix >= 0 && ix < entry.items.length) {
          this.$set(entry.items, ix, {...entry.items[ix], ...(value || {})});
          this.$set(this.control.synopticComponent, "interval", entry);
        }
      } else {
        entry = this?.control?.synopticComponent?.interval || {items: []};
      }
      return (entry?.items || [])[ix];
    },
    updateControl(value) {
      this.$store.dispatch("synoptic/updateControl", value);
    },
    importFromAlarm() {
      if (!this?.control?.data_id) return;
      let srv = new AlarmService();
      let query = {
        contract_id: this.$store.getters["user/contract"].id,
        data_id: this.control.data_id
      };
      this.importing = true;
      srv.fetch(query).then((resp) => {
        this.importing = false;
        if (resp && resp.length) {
          // trigger condition: 1(>) 2(>=)
          let values = {};
          let items = resp
            .filter((alarm) => {
              let r =
                (alarm.alarm_trigger_condition.id == 1 ||
                  alarm.alarm_trigger_condition.id == 2) &&
                !(alarm.limit in values);
              if (r) values[alarm.limit] = true;
              return r;
            })
            .sort((a, b) => a.limit - b.limit)
            .map((alarm, ix) => {
              let item = defaultItervalItem();
              item.value = alarm.limit;
              if (alarm.alarm_trigger_condition.id == 1) item.value - 0.01; // >
              item.color = colorList[ix] || "#000";
              return item;
            });
          if (items.length) {
            this.$set(this.control.synopticComponent.interval, "items", items);
          }
        }
      });
    },
    toggleColorDir() {
      let interval = JSON.parse(
        JSON.stringify(
          this?.control?.synopticComponent?.interval || {items: []}
        )
      );
      if (interval.items.length) {
        this.colorDir = !this.colorDir;
        interval.items
          .map(({color}) => color)
          .reverse()
          .forEach((color, ix) => {
            interval.items[ix].color = color;
          });
        this.$set(this.control.synopticComponent, "interval", interval);
      }
    },
    setDefaultBarColor(value) {
      this.control.synopticComponent.bar.color = value;
      this.control.synopticComponent.fill = "none";
    },
    toggle(obj, prop) {
      return this.$set(obj, prop, !obj[prop]);
    },
    incScalePrecision() {
      let vlr = (this.control.synopticComponent.scale.precision || 0) + 1;
      if (vlr > 3) vlr = 0;
      this.$set(this.control.synopticComponent.scale, "precision", vlr);
    },
    updateBase(control) {
      this.merge = false;
      this.control = control;
    },
    levelInputSetFocus(ix) {
      if (
        this.$refs.levelInput &&
        this.$refs.levelInput.length &&
        ix < this.$refs.levelInput.length
      ) {
        this.$refs.levelInput[ix].focus();
      }
    }
  },
  mounted() {
    this.control = JSON.parse(JSON.stringify(this.controls[0]));
  }
};
</script>

<style scoped>
.toggle-panel {
  margin-bottom: 0;
}

.limit-box {
  margin: 0;
}

.limit-box > label {
  margin-top: 5px;
  margin-bottom: 0;
}

.multi-color-selector {
  margin-bottom: 0.5em;
}

.color-item {
  display: inline-block;
  vertical-align: top;
  margin-left: 5px;
  max-width: 28px;
}

.add-button {
  margin-top: 22px;
}

.eye-icon {
  padding: 0 6px;
}

.color-size {
  width: 100%;
  text-align: left;
  padding: 0 0 0 2px;
  border: 1px solid lightgray;
  border-radius: 3px;
  font-size: 80%;
}
.color-size-error {
  border-color: red;
}
.color-size:focus {
  outline-width: 0;
}

.color-size-perc {
  position: absolute;
  right: 2px;
  top: 6px;
  font-size: 60%;
}

.constant {
  width: 70px;
  height: 28px !important;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  text-align: center;
}

input[type="number"] {
  opacity: 1;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.bottom-toolbar {
  margin: 15px 5px;
}

.bottom-toolbar > div {
  display: inline-block;
}

.button-import-alarm {
  padding: 3px 5px;
  margin-left: 5px;
}

.field-block {
  padding: 5px;
  text-align: center;
  background: whitesmoke;
  margin: 0 -2px;
}

input[type="radio"] {
  margin-left: 15px;
  margin-right: 5px;
}

.label-addon {
  width: 74px;
}

.inline-input {
  background-color: transparent;
  text-align: center;
  padding: 8px 10px 2px 10px;
}

.inline-legend {
  position: relative;
  font-size: 70%;
}

.inline-legend > div {
  position: absolute;
  top: -2px;
  left: 2px;
  width: 100%;
  text-shadow: 0px 0px 1px #aaa;
}

.inline-legend > div > div {
  position: relative;
}

.inline-legend > div > div > div {
  position: absolute;
  z-index: 3;
  background-color: #ffffff73;
}

.inline-legend > div > div > div.clicable:hover {
  color: #aa0101;
}

input.has-error {
  color: #aa0101;
  background-color: rgb(253, 236, 236);
}
.inner-panel {
  padding: 10px 0 0 10px;
}
.interval-form {
  margin-top: -5px;
  margin-bottom: 10px;
  border-radius: 4px;

  padding: 5px 5px 30px 0px;
  border: 1px solid #dadada;
}

input.level-input {
  border-right-color: transparent;
  padding-right: 2px;
}

input.level-input:focus {
  border-color: #d2d6de;
  border-right-color: transparent;
}

div.level-input-unit {
  border-left: none;
  border-right: none;
  padding-right: 50px;
  padding-left: 0;
}
div.level-input-unit:hover {
  cursor: text;
}
</style>
